import revive_payload_client_Oh4Kv7JhjG from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4BK9TjCoVA from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vTqnq81IMm from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_ochTmB7uqX from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_uPPi7U0lx3 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_mFU9WYHJHB from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_DFsDcxjG7C from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4L8QFcfJrZ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_nEb5lM6ozV from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.11/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_MvaPUjciaw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/.nuxt/formkitPlugin.mjs";
import slideovers_ACgK1HuksT from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_jwt-decode@3.1.2_rollup@4.24.0_ts-node@10.9.2_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_hNEXssOqVF from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_jwt-decode@3.1.2_rollup@4.24.0_ts-node@10.9.2_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_PCnyy5Mu07 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_jwt-decode@3.1.2_rollup@4.24.0_ts-node@10.9.2_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_rz3gPkYs9p from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@4.24.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_kGync4158l from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.5.6_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_uQxXoWhtBq from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0T4Bi5RaJ6 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import auth_redirect_5EDtur7iPR from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import sentry_client_69TmzFDTbS from "/opt/build/repo/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_h4q3wt4ro757vlct7osofqhoce/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import chartjs_client_N7GULTnRpX from "/opt/build/repo/plugins/chartjs.client.ts";
import vue_draggable_client_CtEMRI3Db3 from "/opt/build/repo/plugins/vue-draggable.client.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_Oh4Kv7JhjG,
  unhead_4BK9TjCoVA,
  router_vTqnq81IMm,
  supabase_client_ochTmB7uqX,
  payload_client_uPPi7U0lx3,
  navigation_repaint_client_mFU9WYHJHB,
  check_outdated_build_client_DFsDcxjG7C,
  chunk_reload_client_4L8QFcfJrZ,
  plugin_vue3_nEb5lM6ozV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MvaPUjciaw,
  formkitPlugin_pZqjah0RUG,
  slideovers_ACgK1HuksT,
  modals_hNEXssOqVF,
  colors_PCnyy5Mu07,
  plugin_client_rz3gPkYs9p,
  plugin_kGync4158l,
  switch_locale_path_ssr_uQxXoWhtBq,
  i18n_0T4Bi5RaJ6,
  auth_redirect_5EDtur7iPR,
  sentry_client_69TmzFDTbS,
  sentry_client_config_o34nk2sJbg,
  chartjs_client_N7GULTnRpX,
  vue_draggable_client_CtEMRI3Db3,
  vue_query_wrmMkNpEpe
]